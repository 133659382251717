import './Button.css'

const UniversalButton =  () => {
    return (
        <div className='button'>
        <button>Cadastre-se</button>
        </div>
    )
}   

export default UniversalButton