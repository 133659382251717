import RouterApp from "./routers"


function App() {

  return (
    <body>
      <RouterApp />
    </body>
  )
}

export default App
