import './ButtonSteps.css'

const ButtonSteps =  () => {
    return (
        <div className='button-steps'>
        <button>Finalizar e avançar para o próximo</button>
        </div>
    )
}   

export default ButtonSteps