import React, { useState, useRef, useEffect } from 'react';
import './ScreenOne.css';
import ButtonDrawGames from '../../../components/ButtonDrawGame/ButtonDrawGames'
import { Link } from 'react-router-dom';
import { Howl } from 'howler';


const ScreenOne = () => {
	const containerRef = useRef(null);
	const colorInputRef = useRef(null);
	const sizeInputRef = useRef(null);
	const groupRef = useRef(null);


	const [currentColor, setCurrentColor] = useState('#0075ff');
	const [currentSize, setCurrentSize] = useState(5);
	const [svgWidth, setSvgWidth] = useState(500);
	const [svgHeight, setSvgHeight] = useState(500);
	const [initialX, setInitialX] = useState(0);
	const [initialY, setInitialY] = useState(0);
	const [circles, setCircles] = useState([]);
	const [drawing, setDrawing] = useState(false);
	const handleColorChange = () => {
		setCurrentColor(colorInputRef.current.value);
	 };

	const handleSizeChange = () => {
		setCurrentSize(sizeInputRef.current.value);
	};

	const handleMouseDown = (e) => {
		if (containerRef.current) {
			setInitialX(containerRef.current.clientWidth / svgWidth);
			
		 }
		 setDrawing(true);
		setInitialX(containerRef.current.clientWidth / svgWidth);
		setInitialY(containerRef.current.clientHeight / svgHeight);

		const mouseX = e.clientX || e.touches[0].clientX;
		const mouseY = e.clientY || e.touches[0].clientY;
		const relativeX = mouseX - containerRef.current.getBoundingClientRect().left;
		const relativeY = mouseY - containerRef.current.getBoundingClientRect().top;

		setCircles((prevCircles) => [
			...prevCircles,
			{
				cx: relativeX / initialX,
				cy: relativeY / initialY,
				fill: currentColor,
				r: currentSize,
			},
		]);

		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);
	};

	const handleMouseMove = (e) => {
		if (drawing) { }
		const mouseX = e.clientX || e.touches[0].clientX;
		const mouseY = e.clientY || e.touches[0].clientY;
		const relativeX = mouseX - containerRef.current.getBoundingClientRect().left;
		const relativeY = mouseY - containerRef.current.getBoundingClientRect().top;

		setCircles((prevCircles) => [
			...prevCircles,
			{
				cx: relativeX / initialX,
				cy: relativeY / initialY,
				fill: currentColor,
				r: currentSize,
			},
		]);
	};

	const handleMouseUp = () => {
		document.removeEventListener('mousemove', handleMouseMove);
		document.removeEventListener('mouseup', handleMouseUp);
	};

	useEffect(() => {
		if (containerRef.current) {
		   containerRef.current.addEventListener('mousedown', handleMouseDown);
		}
	 
		return () => {
		   if (containerRef.current) {
			  containerRef.current.removeEventListener('mousedown', handleMouseDown);
		   }
		};
	 }, [currentColor, currentSize, initialX, initialY]);

	useEffect(() => {
		setInitialX(containerRef.current.clientWidth / svgWidth);
		setInitialY(containerRef.current.clientHeight / svgHeight);
	}, [svgWidth, svgHeight]);

	useEffect(() => {
		// Reprodução automática de música ao carregar a página
		const sound = new Howl({
		  src: ['../Conhecendo-os-cachos.mp3'], // Substitua pelo caminho real do seu arquivo de música
		  autoplay: true,
		  loop: true,
		});
	
		// Limpeza ao desmontar o componente
		return () => {
		  sound.stop();
		};
	  }, []); // O array vazio assegura que o efeito seja executado apenas uma vez
	
	

	return (
		<>

			<div className="screen-1-game-1">
				<Link to="/tutorial1"><img className='back-game-draw' src="../icon-button-left.svg" alt="botão de voltar" /></Link>
				<h1>Cachinhos ondulados</h1>
				<div className="title-game1">

				</div>
				<div className="container-draw" ref={containerRef}>
					<svg
						className="svg-game1"
						version="1.1"
						id="Layer_1"
						xmlns="http://www.w3.org/2000/svg"
						xmlnsXlink="http://www.w3.org/1999/xlink"
						width="100%"
						viewBox={`0 0 ${svgWidth} ${svgHeight}`}
						enableBackground="new 0 0 464 432"
						xmlSpace="preserve"
					>
						<g id="group" ref={groupRef}>
							{circles.map((circle, index) => (
								<circle key={index} cx={circle.cx} cy={circle.cy} fill={circle.fill} r={circle.r} />
							))}
						</g>


						<path fill="#000000" opacity="1.000000" stroke="none"
							d="
M398.019867,225.019562 
	C402.247894,228.889252 401.603882,233.800781 400.381042,238.470825 
	C399.378876,242.298126 397.610657,245.960434 395.887756,249.554825 
	C393.481628,254.574570 394.000702,259.236115 397.007050,263.739929 
	C398.393402,265.816895 399.858063,267.844635 401.352356,269.846039 
	C408.052887,278.820496 409.124786,288.553955 404.344208,298.531921 
	C399.537628,308.564117 390.706421,312.653595 379.962891,313.160095 
	C378.167511,313.244751 376.364716,313.172028 374.488495,313.172028 
	C373.383728,316.884735 373.616760,320.723755 370.643738,323.581879 
	C369.554260,321.196503 370.845184,319.350403 371.008850,317.440002 
	C371.275543,314.327087 370.457977,312.358307 367.107239,311.631683 
	C363.929443,310.942566 362.039520,312.036041 360.754181,314.896240 
	C357.437073,322.277618 358.824127,326.558685 365.948120,330.495972 
	C370.828583,333.193329 374.301544,336.936035 375.569122,342.580414 
	C376.653290,347.407990 374.883148,351.161560 371.394165,354.127563 
	C368.177734,356.861938 364.634979,356.393524 361.207367,354.195984 
	C360.859985,353.973267 360.313965,354.060486 359.804993,353.997009 
	C358.882629,356.650482 361.207275,357.117554 362.430878,358.203491 
	C365.914886,361.295288 368.447601,364.743103 368.398865,369.750092 
	C368.358337,373.916840 366.868164,377.026611 362.829132,378.199188 
	C358.508423,379.453522 354.734741,378.401520 351.852539,374.174011 
	C354.117188,373.183014 355.158783,375.025238 356.596893,375.394318 
	C359.275513,376.081696 361.896057,376.398773 364.075714,374.205719 
	C366.242401,372.025665 366.135284,369.409821 365.222198,366.753296 
	C364.332550,364.164917 362.699493,362.108704 360.515625,360.438416 
	C357.193573,357.897552 355.900269,361.442871 353.804077,362.588928 
	C349.103668,365.158905 344.170380,366.386414 338.921326,364.655579 
	C333.512543,362.872009 328.506042,360.272949 324.338379,356.310120 
	C323.061981,355.096436 321.558960,353.857697 321.959045,351.511963 
	C323.855774,351.117493 324.362305,352.895416 325.366302,353.723480 
	C329.339478,357.000336 334.347504,357.543884 338.846924,354.753418 
	C343.142822,352.089142 345.672272,348.334747 344.327484,343.022919 
	C343.458557,339.590851 344.606171,337.385559 347.867981,336.001862 
	C344.944763,341.585663 349.547791,348.278534 344.670898,353.378387 
	C345.291168,353.723969 345.538696,353.961090 345.814941,354.001373 
	C353.203400,355.077423 358.817474,353.860535 358.193665,344.469635 
	C358.162659,344.002594 358.886871,343.485413 359.305145,342.931000 
	C360.903931,344.268402 360.081512,345.885712 360.397522,347.246399 
	C361.108063,350.306091 362.923615,352.384735 366.065918,352.749115 
	C369.194855,353.112000 370.954956,351.058289 372.070923,348.425720 
	C374.007599,343.857147 371.836334,338.187469 366.755707,334.657593 
	C364.980713,333.424316 363.085968,332.357483 361.209503,331.277863 
	C359.495728,330.291779 358.046692,329.023315 356.206451,327.751678 
	C357.261627,329.906311 358.021088,332.288361 359.454468,334.152161 
	C360.914490,336.050598 363.037598,337.439087 364.387848,339.568848 
	C359.426422,339.265167 357.212250,335.479279 354.377411,332.016907 
	C344.772034,338.348724 343.126953,329.193542 338.888153,323.386871 
	C334.943848,330.050110 329.741089,334.555908 321.811157,335.515991 
	C324.673492,339.448120 327.919556,340.683319 332.043030,340.739655 
	C338.189148,340.823669 340.699493,342.854309 341.691376,348.077179 
	C338.740753,347.350494 337.720886,343.919556 334.815613,343.629852 
	C332.007172,343.349854 329.171875,343.189148 326.410706,342.654266 
	C325.185242,342.416901 323.957489,341.559265 322.999084,340.687988 
	C319.446991,337.458740 315.310486,335.494659 310.665039,334.453308 
	C307.165009,333.668671 303.291534,333.666443 301.041077,329.229828 
	C296.101929,335.682373 292.268494,341.785736 292.287018,349.492828 
	C292.308868,358.585266 300.458221,364.924896 308.862274,362.578735 
	C310.301910,362.176849 311.941101,361.812103 312.434540,360.190002 
	C312.996368,358.343140 311.476562,357.497345 310.182892,356.763336 
	C307.772430,355.395721 305.854797,353.784729 307.828644,350.856720 
	C309.875244,347.820740 312.784973,346.888275 316.068787,348.760620 
	C318.681610,350.250336 320.306335,352.627075 319.988647,355.772766 
	C319.605896,359.562683 317.223022,361.925781 314.067352,363.893127 
	C306.245758,368.769318 291.079712,364.556519 284.547180,357.187164 
	C277.428741,349.156799 274.490082,339.717651 273.769257,329.343719 
	C273.576477,326.569061 273.740082,323.769623 273.740082,320.029083 
	C263.290680,323.555450 254.813950,330.475494 243.791672,328.401276 
	C233.640076,326.490906 224.089615,322.756714 213.616470,319.121216 
	C213.994492,333.020386 217.871338,347.293854 205.136520,357.535004 
	C200.962677,360.891510 196.985718,364.583527 191.199005,365.700684 
	C199.242599,359.488434 207.149338,353.357300 206.863861,341.911133 
	C206.774353,338.323181 205.822174,335.097107 203.209732,331.832947 
	C201.874710,334.280304 202.335419,336.439392 202.393280,338.445404 
	C202.597595,345.532318 198.490936,350.679810 194.357834,355.571075 
	C191.872391,358.512421 183.646790,357.415039 179.312271,354.338196 
	C175.543320,351.662781 174.425262,347.068085 176.377579,342.277863 
	C178.172836,337.873047 183.634308,334.613342 188.503738,335.194214 
	C190.312714,335.410004 192.077682,335.988922 193.865387,336.390320 
	C195.773148,336.818665 197.564453,336.642334 198.859238,335.010559 
	C200.113831,333.429382 199.230927,331.887878 198.628281,330.318939 
	C197.396896,327.113190 194.737808,325.400696 191.958344,323.855530 
	C180.640503,317.563690 168.663727,316.451019 156.223083,319.562988 
	C152.690292,320.446716 149.229828,321.619568 145.683945,322.909576 
	C149.455032,322.729034 152.990341,323.462341 156.090271,325.555756 
	C159.225616,327.673065 161.277695,330.446411 161.127075,335.709900 
	C157.926285,331.676758 156.446335,327.334625 151.875504,326.599030 
	C147.761642,325.936951 143.500397,325.237091 139.680573,327.512695 
	C135.630997,329.925110 134.334137,333.548401 136.188019,338.938263 
	C137.267960,335.571289 139.134811,334.294037 141.971786,334.766998 
	C144.210129,335.140137 145.561813,336.557861 145.994583,338.682892 
	C146.574921,341.532562 144.850113,343.403290 142.849396,345.034332 
	C138.978256,348.190247 134.343719,346.864777 129.416382,346.871155 
	C132.852859,353.863342 138.558105,356.792084 145.126572,358.559265 
	C149.451675,359.722961 154.034851,359.492981 158.284775,361.266754 
	C160.827316,362.327972 162.190353,360.176666 163.386093,358.319000 
	C164.440643,356.680664 164.386581,354.375610 166.698700,353.151306 
	C167.000366,352.993713 166.985580,352.969330 166.985580,352.969330 
	C165.392395,359.641052 164.490631,365.977753 165.935120,373.198242 
	C167.518234,381.111786 156.418213,390.439362 148.205048,390.047058 
	C142.263794,389.763245 139.119324,385.455933 140.883591,379.756439 
	C142.096207,375.839081 144.467651,372.618652 147.795975,370.196411 
	C150.465088,368.253845 153.228455,366.440735 157.478348,363.519745 
	C133.676880,361.559021 121.510574,348.372314 115.717636,326.501282 
	C112.051224,325.534760 109.490845,320.871246 105.355347,318.041107 
	C107.419640,320.949982 110.173294,323.323334 111.538696,326.717987 
	C113.160149,330.749207 112.530731,334.513336 109.557396,337.329956 
	C105.551231,341.124939 100.572723,343.179688 94.905914,341.975677 
	C90.849998,341.113983 88.928123,337.996582 88.105324,334.162018 
	C87.194344,329.916565 89.479980,326.067719 93.572914,324.798553 
	C98.083824,323.399811 103.665047,325.588867 105.129486,329.331268 
	C106.693787,333.328857 103.994354,336.357849 98.745430,335.978333 
	C99.623436,333.807953 103.606712,333.270294 102.021156,330.165344 
	C100.919746,328.008453 98.888161,327.294556 96.582268,327.278625 
	C93.955818,327.260468 92.162155,328.527039 91.150146,330.925385 
	C89.888847,333.914490 91.944077,337.742828 95.325974,338.720337 
	C99.384346,339.893402 106.237518,337.471497 108.200470,333.935181 
	C110.712639,329.409515 108.389885,325.900726 105.292847,322.827667 
	C101.843140,319.404602 98.459129,316.081451 97.311867,311.031128 
	C96.803032,308.791229 94.279030,308.073090 92.486633,306.918335 
	C87.144646,303.476715 81.917107,299.953735 78.386719,294.441864 
	C75.562920,290.033142 75.293488,285.330353 76.313545,280.334198 
	C77.456787,286.221863 78.916405,291.903381 83.190475,296.348145 
	C87.260185,300.580414 92.136108,303.670471 97.540848,306.654663 
	C101.513939,300.041138 106.689262,295.902344 114.569519,296.353668 
	C114.711555,298.297577 113.251183,298.104675 112.251747,298.363403 
	C109.295525,299.128845 106.628586,300.425354 104.382820,302.509155 
	C100.316490,306.282227 100.303459,308.444519 104.197372,312.396179 
	C107.120071,315.362213 109.916168,318.456909 112.910965,321.346802 
	C115.508759,323.853638 118.302124,324.644623 121.100800,321.467316 
	C122.916504,319.405975 125.622360,318.725861 127.940559,317.304169 
	C127.599281,315.294495 125.542595,314.575684 124.824883,312.922943 
	C124.494698,312.162567 124.019287,311.462830 123.716141,310.693665 
	C122.643707,307.972565 121.915428,304.919983 123.563774,302.478302 
	C128.187653,295.629120 125.509285,284.344391 136.487183,281.335052 
	C139.243027,280.579620 141.974792,280.239380 145.925858,280.643921 
	C143.658173,276.316040 140.886444,275.509766 137.482498,276.634064 
	C132.939545,278.134521 129.316574,281.050537 126.326775,284.764587 
	C125.318916,286.016602 124.870300,287.817017 121.988884,288.485443 
	C123.226219,283.890808 126.625900,281.228394 129.309662,276.907654 
	C116.128044,283.672943 103.248245,283.402863 89.991516,280.289520 
	C91.718620,286.740875 96.631111,289.152557 105.903572,288.611481 
	C112.327332,288.236633 117.499992,284.388947 123.666473,281.391449 
	C120.326202,287.966583 109.943298,292.484283 101.259331,291.433350 
	C94.713043,290.641113 88.664406,288.871307 87.564384,280.851044 
	C87.289619,278.847809 85.474113,278.442261 84.049675,277.649139 
	C74.396248,272.274109 71.351547,266.708618 72.981598,255.953110 
	C73.424026,253.033905 71.624962,250.008698 73.774666,246.894867 
	C76.591728,249.643051 75.146057,254.088211 78.277275,256.900330 
	C79.715439,252.954285 82.992409,250.598969 85.595116,247.033936 
	C75.359993,244.704224 68.112511,239.577774 66.867668,228.794937 
	C66.049866,221.711121 67.060425,215.006210 72.887039,208.683960 
	C73.472298,212.542480 71.412010,214.517365 70.548668,216.764755 
	C69.381599,219.802795 70.077423,221.699249 72.751625,223.466187 
	C78.072838,226.982056 83.143349,226.914520 88.362076,222.909256 
	C89.114975,222.331436 89.704063,221.363708 90.903938,221.830475 
	C88.736267,229.668716 81.744202,230.685928 69.491760,224.955765 
	C68.709915,235.180206 76.777405,243.914139 86.720459,243.764664 
	C89.381691,243.724670 91.327721,242.856537 92.136787,240.293716 
	C96.321510,227.038086 95.582649,214.172806 89.015648,201.250488 
	C86.647942,206.942810 87.635818,212.331955 87.532211,217.736069 
	C84.330063,213.540924 83.961525,208.590866 85.275650,204.040619 
	C86.915054,198.364090 86.421402,193.211517 85.360573,187.509430 
	C83.032776,174.997391 88.093788,163.879028 94.329613,153.489868 
	C102.211136,140.358902 112.273010,129.498505 127.560730,124.733070 
	C130.583054,123.790962 130.486023,123.479683 131.282684,120.407448 
	C129.314941,119.668266 127.777100,121.039955 126.077568,121.496094 
	C116.387337,124.096901 106.966911,124.632729 98.486580,117.968758 
	C93.880264,114.349045 92.755928,110.380630 94.640327,104.829506 
	C96.750793,104.948433 96.131531,106.639229 96.274048,107.791718 
	C97.122231,114.650673 104.162918,120.735306 110.821976,120.264168 
	C111.456017,120.219307 112.110069,119.950584 112.684975,119.651505 
	C123.254349,114.153061 124.249031,98.253464 114.440193,91.943504 
	C114.201233,91.789772 114.096123,91.427971 113.934525,91.159454 
	C116.955399,90.388634 119.265602,91.237320 121.202019,93.823334 
	C126.200958,100.499199 125.271431,107.965988 117.613136,120.182693 
	C125.509148,118.737511 132.511749,118.241608 136.763260,109.737663 
	C143.254333,96.754112 154.510635,90.046577 169.348557,90.947838 
	C174.564545,91.264656 177.204361,89.408806 179.992401,85.039490 
	C190.852676,68.019684 206.915939,60.475712 226.939423,61.315685 
	C229.223618,61.411510 231.191833,60.982521 233.219635,60.233818 
	C255.878586,51.867752 276.043610,56.614143 294.137238,71.927620 
	C295.892090,73.412819 297.417786,75.245453 298.752869,77.127480 
	C299.989380,78.870537 300.827637,80.896118 302.007599,83.109238 
	C316.446533,81.130478 328.460999,86.620361 336.494476,98.275406 
	C344.556335,109.971642 352.382080,122.101768 356.148438,136.191223 
	C357.349762,140.685059 358.741425,145.149384 360.387085,149.498138 
	C362.986053,156.366058 367.599640,162.004883 374.637268,163.961609 
	C390.846130,168.468262 396.459564,180.016708 397.167511,195.314117 
	C397.351593,199.291611 397.195221,203.284851 397.195221,208.106537 
	C403.346649,204.477676 406.805298,199.594254 409.019806,193.879135 
	C411.730743,186.882828 407.648926,182.195847 401.797668,177.862579 
	C406.097534,178.093781 408.209473,179.436768 410.464478,182.748169 
	C416.758911,191.991364 415.621155,205.627960 407.487305,215.247406 
	C404.707916,218.534378 401.389771,221.365829 397.771729,224.742065 
	C390.679626,227.055466 384.405273,229.901733 376.897034,230.529510 
	C384.976562,237.710892 390.391418,251.028397 388.348785,259.577942 
	C383.689178,247.565018 383.700623,233.089096 366.983856,229.718033 
	C376.719299,240.814987 377.712555,243.302628 375.158447,249.749908 
	C374.051300,242.832779 371.614014,237.052017 364.852600,233.083038 
	C364.202667,237.959335 364.176575,241.999893 362.238251,245.585129 
	C360.365448,249.049194 358.494904,252.514496 356.621979,255.981873 
	C369.382599,262.327942 370.894684,266.725861 365.199646,279.938782 
	C369.011810,281.296112 367.219208,283.260864 365.690247,285.068756 
	C357.909241,294.269257 341.032471,298.281677 329.933472,293.637695 
	C324.809021,291.493530 322.317841,287.594513 321.629150,282.154114 
	C320.883575,276.264160 324.156860,271.899078 326.202057,266.838409 
	C331.888824,252.767044 344.934967,250.682693 357.623840,245.129486 
	C355.240479,249.241882 352.301605,250.105118 349.567413,251.201233 
	C344.924957,253.062408 340.231354,254.848251 336.207886,257.886658 
	C330.089142,262.507385 327.292664,270.039551 329.611267,275.727142 
	C332.273621,272.319794 335.829651,270.226105 339.267944,267.928864 
	C349.708130,260.953400 357.083130,251.473328 361.300385,239.662338 
	C363.991089,232.126755 363.007690,230.195007 355.761261,226.973953 
	C348.468750,223.732422 346.236664,219.621750 346.464539,211.645325 
	C346.613770,206.422577 350.071136,203.314026 353.029877,199.791672 
	C341.017731,192.456909 338.536987,188.903336 338.642273,179.358307 
	C340.317902,180.304382 340.477997,182.130493 340.987213,183.626190 
	C344.349121,193.500992 351.323456,197.996933 361.567261,197.536194 
	C366.300598,197.323303 369.741669,198.605042 372.264832,203.092346 
	C374.843079,207.677658 373.896576,211.297318 370.670288,215.176117 
	C365.749420,217.824265 361.075409,218.768555 356.965515,214.753967 
	C352.934113,210.816010 354.466217,206.335083 356.821106,201.667145 
	C351.153625,204.180771 348.646881,208.776245 348.617096,213.998596 
	C348.587036,219.261078 352.533417,222.551544 357.191956,224.683151 
	C370.757202,230.890228 391.850616,227.219238 402.528473,216.800537 
	C408.653870,210.823761 411.949005,203.069290 410.972473,196.701508 
	C403.654907,211.148956 391.321655,216.884491 375.915283,217.333420 
	C373.895294,217.392288 371.817261,217.455460 371.425873,214.926178 
	C377.863586,214.637802 383.847137,214.421402 389.598358,212.418335 
	C393.591492,211.027603 394.720795,208.183136 393.218475,204.535568 
	C390.780640,198.616577 387.416351,193.319260 381.933472,189.606049 
	C376.943909,186.226913 371.619019,183.515335 366.154999,181.023117 
	C357.442169,177.049026 350.256042,171.371780 345.756775,162.670395 
	C345.225891,161.643585 344.997498,160.141190 343.139496,159.770920 
	C344.088745,168.754242 347.262787,176.639908 352.762512,183.715225 
	C350.145935,183.044418 348.564697,181.432281 347.158264,179.543900 
	C343.131622,174.137436 342.126465,167.659119 340.687439,161.350250 
	C338.697174,152.624741 338.476685,143.540527 334.790344,135.147308 
	C328.528961,120.891212 319.980804,109.781891 302.548187,109.853996 
	C301.747864,109.857307 300.885773,109.655930 300.719849,108.065956 
	C304.583466,106.988274 308.446808,107.210510 312.262543,108.416855 
	C326.522430,112.925079 334.109772,123.743637 338.260620,137.185913 
	C340.628143,144.853195 343.285736,152.307861 346.861176,159.474640 
	C350.709534,167.188416 356.408417,172.989578 364.038330,176.988831 
	C375.459534,182.975357 388.238892,187.192810 394.334198,200.316116 
	C393.920776,195.065186 393.172363,190.138916 392.469635,185.206161 
	C391.299347,176.991470 386.868469,171.301407 378.947998,168.889267 
	C364.982117,164.636002 358.013123,154.748032 354.607330,141.107147 
	C350.167786,123.325684 342.312958,107.681702 325.074615,98.576851 
	C319.438477,95.599991 313.907623,92.531425 307.368256,92.383652 
	C304.912842,92.328171 302.008759,92.152222 301.528625,96.148994 
	C313.052063,94.545158 322.222992,99.264221 330.751556,105.699181 
	C342.463074,114.535660 347.783783,127.073692 350.879913,140.885056 
	C352.630951,148.696243 355.822998,155.706848 362.374634,160.796585 
	C363.291382,161.508774 364.746094,162.194107 364.097900,164.216843 
	C359.863708,162.940231 357.052460,159.990875 354.657715,156.644287 
	C350.398590,150.692291 348.498901,143.740845 346.921753,136.753174 
	C342.529877,117.294823 330.738129,104.743164 311.838470,98.796059 
	C305.639740,96.845505 300.594635,98.481865 298.278656,103.023567 
	C296.692932,106.133156 295.291901,109.357521 294.075378,112.629860 
	C292.373901,117.206802 296.247650,121.798378 301.096680,121.134537 
	C306.342590,120.416351 311.000122,122.053123 315.230225,124.932770 
	C325.674744,132.042908 329.860199,142.553391 331.386627,154.615219 
	C333.334869,170.010422 332.084595,186.038712 340.119476,200.281555 
	C340.222748,200.464615 339.941162,200.864822 339.823059,201.211029 
	C337.967590,201.082657 337.475861,199.459991 336.820984,198.252563 
	C333.196930,191.570343 331.422882,184.332535 330.694641,176.809555 
	C329.941437,169.029022 330.116211,161.162628 328.662750,153.449585 
	C326.771729,143.414810 323.411865,134.150955 314.649658,127.861572 
	C311.189636,125.377991 307.316925,123.739578 303.108337,123.538902 
	C299.952515,123.388435 297.312012,124.342178 297.256958,128.595551 
	C297.206909,132.464691 298.385040,134.018921 302.420227,133.746414 
	C305.280579,133.553268 308.220734,134.184128 310.571838,137.729401 
	C307.382843,137.124512 305.022125,136.540756 302.625977,136.265320 
	C300.715576,136.045700 299.054016,136.474747 298.411896,138.799652 
	C296.946381,144.105865 298.294708,147.947067 303.116516,150.778992 
	C306.704132,152.886017 309.239960,155.941650 311.205902,159.435120 
	C315.601532,167.245987 317.876953,175.669662 318.390930,184.655640 
	C318.798615,191.782867 319.288055,198.975540 322.382874,205.603668 
	C324.490692,210.118011 327.606781,213.444702 333.459747,214.133041 
	C331.954346,211.614746 330.738159,209.659302 329.598602,207.660141 
	C324.811462,199.261978 324.938843,189.772720 323.639252,180.603043 
	C322.708496,174.035660 323.261414,167.239685 320.776276,160.914978 
	C317.722626,153.143234 312.988495,146.773682 305.098602,143.017853 
	C308.787689,142.670609 311.633789,144.063400 314.148743,146.347916 
	C322.781097,154.189285 326.182434,164.538773 326.008087,175.573639 
	C325.715302,194.105682 330.668121,209.452194 345.415741,221.977997 
	C356.478577,231.374161 351.501740,247.187180 337.454254,251.088943 
	C332.970703,252.334290 328.470367,253.602097 324.126007,255.250443 
	C308.956268,261.006165 303.840302,279.747711 313.830902,292.530609 
	C315.861206,295.128326 318.466858,296.894440 321.685120,297.488861 
	C331.454651,299.293243 341.390320,300.018219 350.861206,296.758911 
	C365.138855,291.845306 374.983765,282.346130 374.787109,266.127655 
	C374.731476,261.541626 372.722931,257.269958 372.961273,252.218979 
	C379.485413,259.185394 381.423401,284.526703 375.927551,290.901276 
	C375.784576,285.534149 377.578003,280.645630 376.735199,274.606781 
	C375.528900,281.437561 372.642731,286.128662 368.190674,289.914948 
	C366.790924,291.105377 365.569519,292.346954 366.069061,294.590485 
	C366.774017,297.756195 366.284241,300.936554 365.044800,303.897980 
	C363.418274,307.784332 365.377441,309.163422 368.764343,309.466766 
	C374.409363,309.972351 380.088684,310.377808 385.724945,309.585602 
	C389.690063,309.028290 393.182922,307.286530 397.152191,303.484070 
	C389.319702,304.867737 382.703033,306.917908 376.359436,302.592743 
	C377.881653,301.208740 379.235962,301.791840 380.435822,302.086395 
	C384.837463,303.166992 389.080597,302.355865 393.216888,301.007904 
	C402.446014,298.000244 406.716736,289.020508 403.558350,279.866364 
	C401.897003,275.051239 399.144592,270.895264 396.017761,267.000458 
	C391.112244,260.890045 390.074036,254.242432 393.881409,247.504425 
	C397.915863,240.364578 399.607971,233.062653 398.019867,225.019562 
M188.314041,102.217705 
	C173.190430,108.618637 158.132812,115.182869 142.908676,121.335091 
	C137.307281,123.598671 132.403671,125.714195 133.934296,133.130753 
	C133.988297,133.392456 133.372009,133.792496 132.919205,134.292511 
	C130.483521,132.399902 131.715729,129.131210 130.115143,127.175209 
	C103.474678,131.847519 82.271072,172.330246 89.584549,190.592194 
	C90.736229,191.322693 91.425591,188.987930 92.850853,190.308868 
	C92.727226,192.890045 90.063362,195.085876 91.394600,198.199722 
	C95.955353,208.867462 98.987152,219.864960 97.442574,231.724838 
	C99.089928,225.869141 100.263725,220.009476 101.715843,214.219620 
	C104.103653,204.698898 107.552094,195.760223 116.270035,190.007523 
	C117.550743,189.162415 118.866257,188.120926 117.855949,186.351944 
	C114.433510,180.359421 115.969070,174.589554 119.106186,168.523499 
	C107.239410,173.179825 108.833321,182.937912 109.685760,193.558594 
	C103.048737,182.627502 105.123856,168.471680 113.340973,161.032959 
	C118.315033,156.530075 123.947433,152.964279 130.023224,150.165955 
	C140.421967,145.376587 150.949921,150.077087 154.819809,160.965118 
	C156.321716,165.190765 156.456146,169.551575 156.184128,175.111923 
	C157.665512,172.760590 158.406372,171.472702 159.252945,170.258545 
	C166.347107,160.083969 176.396637,155.087784 188.436279,156.569061 
	C200.336975,158.033234 209.579803,154.742783 217.564896,146.127335 
	C223.023621,140.237671 227.206406,133.383606 232.518433,127.410301 
	C234.218948,125.498093 235.854172,123.460373 238.981506,123.702629 
	C231.511734,130.960190 226.995193,140.412003 219.916840,147.884872 
	C211.356171,156.922699 201.437042,161.186737 188.595215,159.574112 
	C173.487915,157.677002 161.357346,166.967468 157.241180,181.596024 
	C155.307770,188.467239 157.268585,194.678986 159.248398,200.783875 
	C156.597916,203.873566 152.561234,205.060486 150.724518,209.068436 
	C158.445877,211.437469 161.392548,206.153000 163.960220,200.190094 
	C164.293243,200.598755 164.626251,201.007416 164.959274,201.416092 
	C164.300568,203.939331 163.641876,206.462570 162.299408,209.237717 
	C159.051575,211.495972 155.497452,212.842331 151.504745,212.316681 
	C148.302704,211.895111 146.642517,213.418961 145.290588,216.097183 
	C143.250015,220.139664 142.037933,224.457230 140.492340,228.671234 
	C136.940720,238.354614 140.862900,247.073471 150.879684,250.831314 
	C150.340317,250.192444 149.897400,249.705933 149.496262,249.187119 
	C138.342209,234.761139 140.648575,223.166718 156.492371,214.274750 
	C158.981552,212.877762 161.462891,211.545609 163.431519,208.596741 
	C164.061478,207.905106 164.393036,206.757126 165.706589,207.097305 
	C165.396652,211.655502 164.502472,212.907837 160.086090,215.394241 
	C154.554871,218.508301 148.792465,221.528625 146.459656,227.968689 
	C143.654266,235.713333 147.079651,242.024857 152.080490,247.506622 
	C157.378662,253.314331 164.403687,255.220474 172.044601,254.465546 
	C174.749786,254.198273 175.468323,252.142715 174.925705,248.582397 
	C168.690292,253.249969 161.867950,253.566940 156.599991,249.433853 
	C151.313660,245.286301 149.332367,236.840866 152.603928,230.341965 
	C155.821884,223.949539 160.664825,218.751968 165.647903,213.849564 
	C168.785492,215.221024 170.939560,217.597946 174.287170,217.666595 
	C174.356796,216.531387 172.431213,215.788864 173.762024,214.592239 
	C175.706284,213.834808 176.896729,216.771927 179.321335,215.863602 
	C177.962112,214.572998 176.881165,213.571732 175.827606,212.542435 
	C164.423874,201.401031 159.942200,177.196091 178.951965,166.568497 
	C182.587341,164.536102 185.077591,163.362167 188.530106,164.679276 
	C200.954575,169.418915 211.285202,165.082657 220.972153,157.568161 
	C230.732162,149.997025 236.254440,139.251938 242.420670,128.985168 
	C254.138336,109.475220 271.480042,103.085739 291.953979,110.924507 
	C294.254822,105.084305 297.136475,99.447517 298.282013,93.196259 
	C299.540863,86.326660 298.868225,80.053650 293.251862,74.879364 
	C274.119507,57.252941 246.760712,53.448383 223.326126,68.803314 
	C222.535522,69.321350 221.602539,69.939255 222.200119,71.310997 
	C222.613815,71.393112 223.183487,71.707726 223.536926,71.546959 
	C233.007095,67.239166 243.323059,67.382164 253.309662,66.391045 
	C265.725250,65.158859 277.507477,68.045769 287.562897,75.952492 
	C290.658508,78.386627 293.318848,81.350945 293.102203,85.824562 
	C291.707367,85.961525 291.337158,85.110954 291.011200,84.428894 
	C288.156738,78.455696 283.007843,75.186447 277.182800,72.829201 
	C257.584686,64.898308 239.374435,68.594574 222.137497,79.674812 
	C212.143829,86.098923 205.130554,95.480057 197.827377,104.552422 
	C185.919296,119.345238 172.784668,132.663910 154.989151,140.422134 
	C152.595764,141.465561 150.255676,142.734802 147.478821,142.243896 
	C151.588287,139.587143 155.817993,137.316284 160.080429,135.108658 
	C162.264587,133.977432 163.731812,132.500198 164.185425,129.885818 
	C165.408066,122.839447 169.231491,117.258545 174.715805,112.846855 
	C179.370560,109.102478 184.213959,105.592583 189.654724,101.806686 
	C193.414536,100.234024 195.742767,97.217026 197.901230,93.664551 
	C189.372543,96.243164 181.004974,94.407593 172.644516,94.091118 
	C169.335083,93.965858 165.967194,93.837723 162.705963,94.289154 
	C154.408447,95.437729 147.540009,99.092949 142.418900,107.062347 
	C151.446518,101.710182 159.873993,99.190285 167.256729,100.197701 
	C175.456940,101.316673 183.597321,103.167381 191.223557,98.120155 
	C191.377350,98.018372 191.797943,98.319672 192.135742,98.448860 
	C192.038620,100.478539 189.712494,100.550827 188.314041,102.217705 
M207.561615,239.218811 
	C203.523117,242.437119 199.032196,243.168777 194.482712,240.781494 
	C186.692566,236.693741 184.066513,229.825256 185.497787,220.705490 
	C180.602463,223.644012 178.331314,227.820953 175.355759,231.312668 
	C174.244781,232.616364 175.098145,233.847122 175.951447,234.897491 
	C180.990967,241.100861 187.184555,245.276016 195.427750,245.648880 
	C198.175003,245.773163 200.932587,245.668503 204.443817,245.668503 
	C196.542435,252.141327 188.873199,247.748489 181.149750,247.051346 
	C180.997375,250.506012 178.857239,253.292618 176.799545,256.075470 
	C187.655106,261.906708 189.434021,267.527954 183.958893,278.216736 
	C182.678207,280.717010 181.137909,283.160980 179.322739,285.295532 
	C174.900452,290.495941 174.924332,292.214539 180.673508,296.442322 
	C196.520020,308.095459 214.073212,316.291534 232.745209,322.357361 
	C244.356049,326.129242 255.272034,325.391724 265.978088,319.496979 
	C272.531799,315.888519 279.388123,312.827423 285.920929,309.184113 
	C290.266418,306.760620 294.465576,303.982330 298.401489,300.937805 
	C304.857056,295.944244 307.301086,289.352966 305.854706,281.124634 
	C304.002686,270.588837 307.974365,262.234619 316.412537,255.876373 
	C317.867645,254.779953 319.795441,253.959091 320.203461,252.054565 
	C322.188385,242.789749 323.936920,233.479279 326.341064,224.299454 
	C327.028320,221.675400 324.090912,220.798874 322.336304,219.850601 
	C320.932556,219.091965 319.562103,220.293228 318.470215,221.665131 
	C319.488983,222.230606 320.439606,222.758255 321.314758,223.244034 
	C320.032257,225.790100 318.902100,228.167969 317.655579,230.483170 
	C313.940704,237.383057 310.127258,244.073776 301.748596,246.502716 
	C295.229736,248.392517 289.328491,248.135025 283.766266,243.759979 
	C285.006622,242.282394 286.210388,242.720306 287.291992,242.917236 
	C298.805328,245.013565 306.898285,239.949417 313.041534,230.720352 
	C313.698456,229.733444 314.636871,228.716949 313.649597,227.599197 
	C310.860474,224.441452 308.858795,220.433853 304.673523,218.689270 
	C303.567200,219.913315 304.226624,221.092804 304.259155,222.186584 
	C304.554443,232.106049 298.901245,238.720505 289.856720,238.976227 
	C281.677277,239.207504 275.904907,232.615341 276.483398,222.770142 
	C276.635925,220.174591 274.630096,220.442688 273.899536,219.445953 
	C278.863647,209.202286 291.070862,207.615891 301.162476,211.040726 
	C306.205200,212.752106 310.736237,215.033661 314.696747,210.813095 
	C316.667725,211.141571 315.405365,213.020950 317.190063,213.009659 
	C317.373291,212.267120 317.802124,211.469284 317.718262,210.729538 
	C316.239349,197.680374 316.359528,184.405624 313.257416,171.604202 
	C311.582214,164.691132 308.782043,157.884354 302.439484,153.546509 
	C300.433258,152.174393 297.477234,150.882965 297.506134,154.877975 
	C297.593842,167.001572 290.667908,175.375046 283.212799,183.624283 
	C280.941589,186.137466 278.232788,188.363434 276.828613,191.800964 
	C281.384094,194.281830 285.773468,195.923569 290.808380,195.741196 
	C296.819611,195.523438 302.835388,195.918839 308.587799,197.830505 
	C311.006470,198.634308 313.903351,199.164825 315.525574,203.554337 
	C306.100403,198.578918 297.233429,199.126648 288.234863,200.168411 
	C282.529114,200.828934 276.911682,200.355484 274.079803,194.243637 
	C270.033020,197.829178 266.610596,201.274902 262.776978,204.177567 
	C252.877319,211.673248 236.121384,215.007019 224.226868,200.902252 
	C221.556915,197.736160 218.449799,194.910599 215.361725,192.130676 
	C206.942291,184.551315 185.809494,187.954987 180.278412,201.171906 
	C183.597672,200.662231 186.827332,199.997391 190.090225,199.711243 
	C193.580750,199.405136 197.037659,200.009476 200.512131,200.514175 
	C204.929291,201.155823 209.451584,201.849976 213.770035,199.780090 
	C215.059891,199.161835 216.281906,197.957291 218.260895,199.394531 
	C217.567902,203.535507 214.210968,204.512558 210.821396,205.109924 
	C206.955399,205.791245 203.240936,204.532089 199.516129,203.627747 
	C193.631836,202.199081 187.690704,201.221024 181.883469,203.798248 
	C179.824936,204.711807 176.369141,204.911469 177.514175,208.605362 
	C178.535233,211.899323 180.879318,214.186478 184.549698,213.727783 
	C187.144363,213.403503 189.619171,212.194122 192.176651,211.485077 
	C202.027603,208.754013 211.334946,212.360306 215.877838,220.906876 
	C213.218552,221.643692 213.262756,223.694305 213.484024,225.946823 
	C213.994019,231.138672 212.109222,235.397461 207.561615,239.218811 
M278.669037,169.136871 
	C284.946930,154.551437 284.460907,140.434372 276.295380,126.561882 
	C272.029846,119.315147 270.903931,118.918625 264.853455,124.784271 
	C262.321594,127.238785 260.186615,130.010513 258.542236,133.117294 
	C255.974411,137.968750 253.658066,142.956879 250.994629,147.753540 
	C240.812744,166.090256 222.118729,181.480743 198.816574,175.724960 
	C186.321686,172.638641 175.941833,181.932449 174.457397,195.774567 
	C175.012543,194.687042 175.330811,194.139832 175.580582,193.562973 
	C180.875671,181.334442 188.820908,177.299789 201.846970,179.436798 
	C209.035339,180.616104 214.278290,185.537582 220.807770,188.029495 
	C237.967392,194.578201 252.301849,190.232697 261.049469,174.905838 
	C267.131805,164.248871 268.521545,152.661499 268.063568,140.660004 
	C267.981293,138.504715 267.317963,136.121307 269.242584,133.654617 
	C269.698090,134.747543 270.075714,135.296249 270.149719,135.883224 
	C272.237549,152.448029 269.809845,168.108307 259.837860,181.867035 
	C254.274796,189.542648 246.634155,193.663055 236.733017,193.474915 
	C230.862152,193.363358 225.238907,192.721085 219.468307,189.642990 
	C223.523712,199.764359 232.817184,205.805420 242.055542,204.564240 
	C247.210144,203.871735 251.976303,201.812851 256.324219,198.881393 
	C266.971619,191.702698 273.248230,181.206161 278.669037,169.136871 
M153.025497,313.200134 
	C152.347397,313.978027 150.950500,314.093323 150.828842,315.588623 
	C154.853577,314.818420 158.725113,314.086914 162.297867,312.117371 
	C173.549683,305.914551 174.936584,296.995941 165.407471,288.146667 
	C175.873672,287.919281 179.709457,280.531555 182.955276,272.685608 
	C185.575912,266.350861 183.481064,262.003845 177.139694,259.630157 
	C171.474136,257.509460 165.427628,257.461792 159.614029,256.016388 
	C166.303375,260.906647 172.846878,265.759277 171.918365,275.220062 
	C171.472870,279.759369 168.479324,282.986542 164.826065,283.942841 
	C171.506287,270.093842 170.078033,265.174774 155.922440,257.606567 
	C148.524078,253.651077 141.370651,249.747192 137.500549,241.497635 
	C135.379974,244.079193 135.474152,246.539246 134.661804,248.670425 
	C131.993530,255.670471 130.477585,263.473145 120.726578,264.610016 
	C118.167160,264.908386 115.779091,267.273163 112.953766,267.780396 
	C110.008286,268.309204 107.033119,268.675201 104.067802,269.090027 
	C101.470169,269.453430 98.903236,268.690643 96.326714,268.690979 
	C109.347137,274.185730 121.988518,273.343170 134.870026,265.237701 
	C132.385605,269.870148 131.869995,270.560242 128.864838,271.768372 
	C115.670349,277.072784 102.881660,276.794159 90.723259,268.759216 
	C85.799988,265.505676 80.878334,262.249695 75.789803,258.884979 
	C75.204605,267.287415 78.593590,272.258514 87.288963,275.774689 
	C98.860283,280.453888 110.699326,281.144592 122.380363,276.595490 
	C129.824966,273.696259 136.993118,269.917755 141.880432,263.175812 
	C143.631729,260.759918 144.897186,257.991852 146.384399,255.384537 
	C146.702133,264.947418 139.733139,269.654968 133.676086,275.064789 
	C136.446701,273.756409 139.260757,273.746429 142.106491,273.683777 
	C146.358780,273.590179 143.328537,268.714966 146.880707,266.851501 
	C145.905884,278.323700 152.154556,286.748047 156.445480,295.933746 
	C155.183380,287.153839 151.624130,278.547485 155.557663,269.639343 
	C155.520020,277.995850 156.507584,286.151276 158.950928,294.166626 
	C161.166809,301.435730 158.664093,307.548553 153.025497,313.200134 
M219.513382,162.136856 
	C209.580414,168.868378 199.035095,171.607407 187.369003,167.079391 
	C186.097687,166.585953 184.881577,166.416534 183.531448,167.088928 
	C169.607193,174.023514 165.003723,187.616180 170.542297,197.771271 
	C173.453903,179.743256 183.889343,170.490280 199.783478,173.067764 
	C217.550369,175.948959 230.857330,169.319031 241.905182,156.014923 
	C248.280792,148.337296 251.903275,139.129456 256.942261,130.716263 
	C260.075653,125.484787 263.965668,120.968033 269.562866,118.256180 
	C275.687500,115.288780 279.129669,116.085007 283.864838,120.882088 
	C288.308655,125.384010 291.090668,130.982422 294.500214,136.286697 
	C294.361145,129.038712 293.984924,121.956039 290.141815,115.593460 
	C288.512299,112.895691 286.281097,110.851868 283.164215,110.379509 
	C277.866852,109.576721 272.605804,109.652122 267.382019,111.381775 
	C255.896683,115.184692 248.455994,123.295876 242.736572,133.520767 
	C236.782364,144.165405 230.416199,154.539780 219.513382,162.136856 
M311.044617,306.001740 
	C312.830353,304.973785 314.769958,304.564636 316.807587,304.659760 
	C330.007477,305.275970 343.332855,304.388458 356.361603,306.940643 
	C361.368042,307.921356 362.714020,305.678894 363.454742,301.909973 
	C363.889587,299.697357 364.361816,297.346222 363.095520,295.048431 
	C362.563324,295.116821 362.020691,295.044464 361.634735,295.256836 
	C349.576172,301.891785 336.901154,303.587219 323.486908,300.382050 
	C318.125793,299.101105 312.656525,298.469971 307.211273,300.270386 
	C303.465057,301.509033 300.495361,303.615662 300.239899,307.829346 
	C299.844635,314.348206 301.650269,320.213470 306.087372,325.155487 
	C311.631958,322.160065 318.097961,320.281555 314.753357,311.614624 
	C318.082733,312.766815 318.170837,314.826874 318.261200,316.791107 
	C318.541931,322.890839 312.526306,323.857513 308.879333,327.621704 
	C313.361237,331.477722 318.284729,332.194763 323.459198,331.657410 
	C329.965881,330.981720 335.107483,326.297241 335.641327,320.330078 
	C335.978149,316.564697 335.982391,312.774536 337.446014,308.342255 
	C327.513184,309.219055 317.786682,303.076508 308.828064,310.921539 
	C308.216156,308.888550 309.283783,307.658203 311.044617,306.001740 
M117.151024,219.373672 
	C115.710419,219.975739 114.679115,221.699036 112.431999,220.939743 
	C114.058952,216.788544 117.681618,215.729156 121.194885,215.903763 
	C130.194168,216.351028 134.280518,222.510025 136.128006,231.419388 
	C140.664825,224.800720 140.971848,217.782455 144.263519,212.126938 
	C138.559021,207.972763 136.330215,212.907806 133.354446,215.332672 
	C132.029495,213.370026 134.276627,211.914093 133.421722,210.283859 
	C121.303505,206.505905 107.869728,213.816299 104.870697,225.835922 
	C102.418442,235.664139 110.179024,243.268036 119.892624,240.439529 
	C123.959747,239.255219 126.372818,236.357254 126.653259,232.092453 
	C126.915329,228.107025 124.599442,225.542923 121.215775,223.900848 
	C119.259186,222.951340 117.303795,223.297958 115.574921,224.605637 
	C114.039284,225.767136 113.841591,227.408859 114.301582,229.110352 
	C114.842834,231.112427 116.039764,232.277664 118.204414,231.705994 
	C119.245369,230.841110 117.540970,229.202057 119.751221,228.537354 
	C121.421638,229.712845 122.888451,231.189499 121.442581,233.635315 
	C120.203217,235.731796 118.032227,235.625519 116.099533,234.859756 
	C112.616447,233.479721 110.791954,230.667404 111.190514,227.005951 
	C111.615547,223.101334 114.499138,221.405182 118.179199,221.144302 
	C123.165977,220.790787 127.507721,223.792435 128.919205,228.388931 
	C130.479935,233.471512 128.624786,238.429016 124.025696,241.387665 
	C121.118645,243.257797 117.917450,243.949310 114.493225,243.620789 
	C111.065338,243.291885 108.079536,242.041779 105.624352,239.531143 
	C103.398186,237.254684 102.009773,234.552429 101.233421,230.861465 
	C100.219307,237.293015 100.219307,237.293015 104.440735,240.866241 
	C109.769196,245.376556 120.485725,246.469894 126.647606,243.131897 
	C132.346649,240.044632 134.469894,233.403259 131.908356,226.676636 
	C129.431320,220.171921 124.595306,217.550797 117.151024,219.373672 
M106.920944,206.528076 
	C105.468918,211.759537 104.016884,216.990997 102.564850,222.222458 
	C109.222847,209.113632 120.214333,204.932343 134.071198,206.607910 
	C136.454819,206.896133 138.209396,206.171921 139.920166,204.611328 
	C143.563736,201.287643 147.938950,199.703827 154.237015,198.991791 
	C149.525803,202.981323 144.063522,203.348038 141.093124,207.639557 
	C143.287872,208.880127 145.351089,209.246445 147.038742,207.981583 
	C150.322220,205.520660 153.406509,202.784042 156.443878,200.019424 
	C156.760941,199.730850 156.306915,198.453110 156.043854,197.691940 
	C154.144043,192.194763 153.140976,186.609039 154.876617,179.461685 
	C153.487411,181.475540 152.960861,182.289474 152.384613,183.066605 
	C144.365601,193.880981 134.946716,196.529800 122.472015,191.299057 
	C120.697617,190.555023 119.713554,191.283920 118.439034,192.111343 
	C113.228058,195.494202 109.801033,200.302200 106.920944,206.528076 
M112.136513,261.710480 
	C118.196899,259.127777 122.807732,255.365128 123.835030,247.563965 
	C114.419243,247.854858 105.435104,248.221130 99.371239,238.723648 
	C96.594803,245.786453 91.482704,249.071747 86.168976,252.260361 
	C83.866249,253.642151 80.741348,255.178848 81.664429,258.652344 
	C82.504654,261.814056 85.341774,263.177460 88.429390,263.814209 
	C96.267998,265.430847 103.945465,264.974518 112.136513,261.710480 
M289.305389,133.221100 
	C284.691498,124.327904 280.549957,120.143860 274.788086,119.400696 
	C286.903442,135.804443 288.876587,152.942932 280.755219,171.126160 
	C273.090546,188.286774 262.823242,202.688400 243.090866,207.427444 
	C251.222092,206.913696 258.421600,204.537827 264.599548,199.098206 
	C273.653503,191.126190 281.048004,181.689575 288.722015,172.498962 
	C290.395813,170.494370 292.171570,168.057724 292.577911,165.601318 
	C294.358978,154.834717 294.947510,144.056854 289.305389,133.221100 
M125.105530,187.931747 
	C126.474663,191.677963 129.766418,191.704453 132.830246,191.601868 
	C142.649536,191.273087 152.574341,182.128174 153.497147,172.394089 
	C154.213425,164.838394 152.148438,158.088516 145.702606,153.500488 
	C139.547928,149.119675 133.366806,150.908310 127.415855,154.270645 
	C124.666054,155.824295 121.867096,157.341522 119.319077,159.190384 
	C115.011757,162.315842 111.175529,165.913437 109.549515,171.879181 
	C112.172112,169.893112 114.279144,168.106781 116.569427,166.598770 
	C123.482384,162.047012 130.701904,158.612900 139.409439,159.670425 
	C147.161148,160.611877 152.490540,168.523392 148.741135,173.953659 
	C144.425186,162.811844 142.027481,160.954422 134.276535,162.748413 
	C144.303360,166.316315 147.392929,173.410721 141.791580,180.351059 
	C138.973206,183.843140 135.078629,185.691544 130.646179,186.400085 
	C128.896576,186.679749 126.925514,185.852158 125.105530,187.931747 
M194.353210,91.242271 
	C196.887589,90.342003 199.391388,89.226601 201.199158,87.253380 
	C208.310745,79.490852 214.111633,70.470276 224.297348,64.843300 
	C207.906082,60.951614 183.056488,77.873619 180.248199,91.296486 
	C184.682434,92.326012 189.110764,92.190674 194.353210,91.242271 
M333.946411,249.103638 
	C341.799042,247.787994 347.508820,243.775558 348.309113,235.486877 
	C349.000183,228.330032 343.611969,224.686859 338.264526,221.286911 
	C332.735748,217.771652 326.109741,215.976303 321.213287,209.618546 
	C322.729370,217.094955 326.693634,220.509949 332.812744,221.634445 
	C335.786560,222.180939 338.710236,222.842346 341.177002,224.661163 
	C344.388947,227.029388 345.780396,230.302353 345.204224,234.246246 
	C344.534821,238.828339 341.173187,243.000717 337.591919,243.889206 
	C338.077057,241.271942 340.076508,239.578690 341.299866,237.452560 
	C343.017426,234.467529 343.649780,231.296844 341.252838,228.629974 
	C338.488129,225.553940 334.499817,224.555481 330.492950,224.098480 
	C330.004944,224.042801 329.109009,225.054855 328.866821,225.737045 
	C326.120361,233.472321 325.212067,241.564407 323.302917,250.885620 
	C330.829346,244.143616 333.805603,236.153336 338.599792,229.391632 
	C337.457367,237.200012 333.125885,243.413513 328.431702,250.303894 
	C330.501068,249.890045 331.878448,249.614578 333.946411,249.103638 
M134.389862,328.273956 
	C137.262177,323.967316 141.841293,321.965210 146.297943,319.720398 
	C141.333725,319.412811 136.455444,318.878265 131.752747,319.558228 
	C126.724869,320.285248 122.049736,322.614197 120.505592,328.116699 
	C119.033226,333.363434 121.255600,337.524261 125.334190,340.822815 
	C128.866852,343.679810 137.174042,344.935516 140.491928,343.087219 
	C141.061752,342.769806 141.665176,342.384094 142.041397,341.873810 
	C142.894684,340.716553 143.206726,339.386505 142.034210,338.276093 
	C140.886581,337.189240 139.892426,337.967316 139.054398,338.868103 
	C137.963623,340.040558 137.245178,341.726044 135.235703,341.576050 
	C132.223816,336.646759 132.010483,334.474579 134.389862,328.273956 
M210.513016,326.149536 
	C209.755798,322.766724 209.802719,318.771179 206.633896,316.708344 
	C200.475769,312.699432 194.000290,309.178009 186.598892,304.834076 
	C188.319641,310.497986 186.193024,314.069092 184.043320,317.548889 
	C189.309937,320.046814 194.210541,322.371155 199.090408,324.685669 
	C200.366913,323.086121 197.929855,321.360260 199.962189,319.537476 
	C202.791016,327.141632 209.043198,332.758057 210.347305,341.608032 
	C211.873444,336.243652 210.797501,331.617706 210.513016,326.149536 
M339.072754,312.500702 
	C338.386780,316.302979 343.924622,328.465454 347.138458,330.215088 
	C349.670471,331.593506 354.375031,330.022583 354.378937,327.329926 
	C354.387634,321.358765 356.697052,316.150391 359.470001,310.618408 
	C353.934082,309.242340 349.085358,308.496948 344.128265,308.333160 
	C341.585846,308.249176 340.102692,309.496674 339.072754,312.500702 
M290.209076,334.275024 
	C284.734131,330.473114 283.482971,326.368866 286.402771,320.673981 
	C288.069977,317.422180 290.026917,314.289429 293.659790,312.727386 
	C296.334534,311.577362 297.465820,309.762421 295.806213,306.563507 
	C291.911530,308.763031 286.604248,310.069275 285.140656,313.621002 
	C282.349670,320.394287 279.247925,327.506744 280.314941,335.285858 
	C281.069061,340.783752 282.890961,342.342041 288.653442,342.242157 
	C285.912048,345.748474 283.119354,344.463654 279.892029,342.739777 
	C282.405334,348.548798 286.861389,352.113037 290.020660,356.769440 
	C288.312286,350.166290 288.782898,344.059631 292.055603,338.238586 
	C292.986694,336.582520 292.482300,335.388000 290.209076,334.275024 
M348.085114,270.171509 
	C348.417633,271.009460 349.613525,271.644287 348.693665,272.824646 
	C345.656433,273.214783 345.789307,269.524841 343.400299,268.729553 
	C341.403381,270.029144 339.292358,271.337677 337.251251,272.747406 
	C334.510315,274.640533 334.725616,276.737366 336.934570,278.938751 
	C342.603424,284.588165 355.797729,282.764893 359.805511,275.732666 
	C361.257507,273.185028 361.240967,270.570282 359.328094,268.287384 
	C357.095581,265.623077 353.998993,264.981659 350.724457,265.478882 
	C348.517761,265.813934 346.999542,266.981201 348.085114,270.171509 
M162.313431,377.597046 
	C163.533630,375.501678 164.141586,373.167084 163.526642,370.877167 
	C162.831726,368.289398 161.665405,365.365326 158.073303,367.191315 
	C153.030869,369.754639 148.063019,372.541382 145.038544,377.673431 
	C143.671295,379.993439 142.026337,382.432861 144.193253,384.979950 
	C146.305664,387.463013 149.266830,387.282684 152.047318,386.323914 
	C156.296585,384.858734 159.349670,381.810669 162.313431,377.597046 
M134.198410,179.247467 
	C133.232910,180.487518 132.267410,181.727570 130.988159,183.370590 
	C136.342743,181.589096 138.549942,180.107895 140.359909,177.281998 
	C142.745300,173.557709 141.886139,169.672760 138.177597,167.413956 
	C134.192749,164.986908 127.063118,165.885284 123.254349,169.294357 
	C118.575394,173.482300 117.500511,178.013123 119.869164,183.423492 
	C125.478790,184.040237 128.654694,179.965469 132.257401,176.869247 
	C133.470383,175.826797 134.347305,174.042328 132.916245,172.428818 
	C131.672989,171.027054 130.210815,171.839691 128.776855,172.356552 
	C127.366234,172.864960 126.500237,174.937790 124.206703,173.822632 
	C126.578445,170.280930 129.519821,167.529648 133.644196,169.602783 
	C137.779083,171.681259 136.557114,175.358383 134.198410,179.247467 
M158.270905,227.015381 
	C157.269440,228.726456 156.074326,230.354538 155.299484,232.162704 
	C151.638504,240.706024 157.069931,248.952438 167.056137,248.815094 
	C165.508270,240.462234 164.531601,232.156754 161.363617,222.489380 
	C159.927261,224.576599 159.282532,225.513474 158.270905,227.015381 
M190.390030,104.996140 
	C172.798782,116.359192 166.966965,122.792931 167.661346,130.070724 
	C177.894241,123.630890 192.780243,108.450966 196.491791,100.403717 
	C194.388474,101.992439 192.675705,103.286171 190.390030,104.996140 
M177.756744,347.360077 
	C178.309219,350.005157 179.765259,351.746735 182.612625,352.422607 
	C181.586166,347.845062 181.586166,347.845062 184.003693,345.679840 
	C185.107452,350.265625 188.933487,353.436127 191.021637,351.488617 
	C190.548950,350.557617 188.653870,350.552521 189.430939,348.575195 
	C189.976929,348.362701 190.722458,348.066772 191.472168,347.781830 
	C194.294769,346.709076 195.044678,344.352264 194.828583,341.726288 
	C194.593674,338.871613 192.126221,338.705383 189.981674,338.409027 
	C183.455170,337.507111 180.383759,339.618744 177.756744,347.360077 
M323.842865,280.889526 
	C324.495483,292.731171 340.564453,295.805847 350.800110,290.303009 
	C339.059906,289.008911 327.531189,287.399139 324.435822,272.796234 
	C324.177246,275.244751 323.918671,277.693268 323.842865,280.889526 
M303.825653,85.933739 
	C301.773499,87.758438 301.093170,89.608139 304.771576,89.448608 
	C311.213501,89.169243 316.807159,91.482147 322.205231,94.426147 
	C326.872192,96.971420 331.329773,99.900604 335.879883,102.660080 
	C328.183228,91.818687 318.820923,84.084854 303.825653,85.933739 
M288.068817,324.770233 
	C287.700592,328.227051 288.361115,331.381470 292.195312,332.211182 
	C296.078369,333.051544 297.097076,329.615234 298.466705,327.014343 
	C300.916626,322.361908 298.786804,318.513214 296.487183,314.446075 
	C291.669403,316.187408 289.330688,319.561249 288.068817,324.770233 
M145.658447,111.538277 
	C149.249527,110.719788 152.896011,110.796402 156.549988,110.740601 
	C162.116119,110.655602 166.502731,108.191200 170.250214,103.857071 
	C162.135605,100.534348 146.876617,105.345871 142.630951,112.171967 
	C143.430969,111.973404 144.230988,111.774834 145.658447,111.538277 
M145.332291,283.832703 
	C136.042603,282.036316 128.702072,287.617188 130.174255,295.980164 
	C132.049866,291.485107 134.994827,288.891510 139.607193,288.749084 
	C141.903458,288.678223 144.420425,288.304138 145.999588,290.517365 
	C147.870316,293.139252 144.536209,294.116638 144.048187,296.016205 
	C143.974854,296.301666 144.128540,296.645477 144.176346,296.956604 
	C148.336731,297.832275 151.097870,295.622955 150.379120,291.716858 
	C149.838638,288.779541 148.775772,285.940186 145.332291,283.832703 
M203.007996,184.955765 
	C204.079819,184.765747 205.316620,185.363052 206.672836,183.802170 
	C200.317825,180.838715 194.194885,180.083679 187.967972,183.130493 
	C182.278976,185.914124 179.067413,190.594696 178.280273,197.007538 
	C184.729172,189.744537 192.735840,185.862381 203.007996,184.955765 
M366.634003,202.664795 
	C365.156250,202.607147 363.586487,202.203369 362.220642,202.566574 
	C359.664093,203.246353 357.992279,205.176636 357.279175,207.706421 
	C356.690857,209.793472 357.362152,211.677536 359.104309,213.015503 
	C361.812073,215.095062 367.911255,214.183533 369.966309,211.367767 
	C372.846252,207.421799 370.725922,204.968140 366.634003,202.664795 
M134.510071,306.926575 
	C134.115356,306.014679 133.869659,304.992981 133.295380,304.213348 
	C132.039902,302.508972 130.666046,300.187378 128.235977,301.150543 
	C125.653961,302.173920 125.917641,304.863159 126.202553,307.235870 
	C126.397171,308.856689 127.494949,309.873901 128.601410,310.890961 
	C132.877914,314.821869 139.310181,315.787445 145.144012,313.233917 
	C141.163208,312.109161 137.064087,311.497314 134.510071,306.926575 
M140.739822,295.333801 
	C140.911530,294.094391 142.659698,293.387207 141.902481,291.658783 
	C141.126266,291.587891 140.284592,291.333527 139.512848,291.471161 
	C136.127884,292.074951 133.486115,293.782440 133.302475,297.471466 
	C133.113907,301.259552 136.279221,301.813477 139.059509,302.346436 
	C143.442093,303.186584 147.599731,301.910248 151.687622,300.571167 
	C153.156525,300.089996 154.343414,299.078094 152.957840,297.264984 
	C146.102982,302.508850 142.592957,302.127472 140.739822,295.333801 
M348.677612,362.346252 
	C350.829376,361.295105 353.020874,360.286865 354.167542,357.615265 
	C346.811005,354.635071 340.815186,359.237244 334.287598,359.901276 
	C338.597412,362.432251 342.813873,364.979370 348.677612,362.346252 
M147.571091,304.482330 
	C143.861420,305.642731 140.088623,305.285614 136.119385,304.517792 
	C138.212891,309.107147 142.970581,311.533844 147.550644,310.422577 
	C151.375565,309.494537 154.172684,307.438965 154.973633,302.448029 
	C152.129211,303.262390 150.252670,303.799652 147.571091,304.482330 
M118.532166,262.570679 
	C127.015404,258.503967 132.186356,252.051727 132.987808,242.358948 
	C130.993790,244.607330 126.768555,244.854843 126.341286,247.927414 
	C125.369026,254.919220 121.638710,259.713470 116.197990,263.657104 
	C116.790161,263.378235 117.382339,263.099365 118.532166,262.570679 
M167.785721,231.935806 
	C166.967316,237.395813 168.685562,242.388321 169.960754,247.657150 
	C171.686523,247.195099 172.388062,246.583817 172.855972,245.647552 
	C174.021591,243.315277 171.867279,233.293579 169.769974,231.653061 
	C169.460129,231.410690 168.823563,231.586014 167.785721,231.935806 
M365.610718,268.550385 
	C365.173157,263.556641 362.003204,260.960510 357.558411,259.682434 
	C355.352448,259.048126 353.087860,258.989227 351.464905,262.104187 
	C358.869202,262.568390 363.641724,265.753479 364.244537,273.735077 
	C365.748260,271.917755 365.545868,270.562927 365.610718,268.550385 
M174.289505,312.806305 
	C176.465500,310.134430 175.989548,307.361450 174.498642,303.466736 
	C171.463501,308.914398 167.948334,312.882568 163.030121,315.467346 
	C166.639282,314.652863 170.540237,315.774261 174.289505,312.806305 
M136.574600,117.718842 
	C135.773453,118.565613 134.839279,119.379814 136.028687,120.945396 
	C141.614883,119.191673 146.871124,116.580711 153.097290,113.454796 
	C146.701187,113.678452 141.799744,115.036705 136.574600,117.718842 
M166.776733,221.772324 
	C166.676987,219.494171 169.247482,221.520981 169.655426,219.873993 
	C168.609344,218.475937 167.637497,216.618225 165.442230,218.377121 
	C163.212784,220.163467 164.197739,225.037872 168.503876,229.526352 
	C166.174774,225.130554 169.509277,226.191269 170.631927,224.985382 
	C169.397995,224.012192 168.247314,223.104660 166.776733,221.772324 
M179.482254,313.763702 
	C179.000305,314.592590 178.788696,315.522125 180.174515,315.130798 
	C182.538116,314.463318 184.251648,312.886230 184.613739,310.360535 
	C185.011475,307.586029 184.195190,305.218384 181.642792,302.676483 
	C181.108292,306.925598 181.997482,310.367767 179.482254,313.763702 
M218.584244,78.392982 
	C218.595871,78.229637 218.660309,78.044388 218.604202,77.909149 
	C218.548767,77.775475 218.330856,77.576363 218.253586,77.603493 
	C212.297318,79.694817 208.875366,84.338715 206.185226,89.721558 
	C210.216141,86.082184 214.247040,82.442810 218.584244,78.392982 
M280.839325,317.504883 
	C280.924255,316.685883 281.098114,315.623749 279.783783,315.997772 
	C278.106140,316.475220 276.887024,317.640533 276.533722,319.415771 
	C276.011902,322.037781 277.165466,324.443756 277.453857,328.181366 
	C278.790497,324.031555 279.744415,321.069946 280.839325,317.504883 
M316.869385,355.785919 
	C315.554626,350.999664 314.737732,350.564362 310.906342,352.608429 
	C314.617798,357.049896 314.617798,357.049896 316.869385,355.785919 
M333.426178,279.051697 
	C331.212677,279.790375 331.030884,280.084045 332.677734,281.423523 
	C335.390350,283.629883 338.365265,285.445251 342.153656,285.253479 
	C338.744263,284.181702 335.869293,282.404449 333.426178,279.051697 
z"/>
						<path fill="#000000" opacity="1.000000" stroke="none"
							d="
M167.011002,353.002869 
	C162.772217,343.957764 162.459106,334.733093 165.152649,324.892914 
	C167.745056,326.315369 166.655823,327.867401 166.567902,329.216278 
	C166.146301,335.684021 165.285233,342.217834 168.089966,348.391602 
	C172.296570,357.651154 182.505325,361.681549 192.912109,358.357605 
	C194.434250,357.871399 195.841187,356.726257 197.641373,357.372894 
	C191.213989,365.547302 175.326996,363.294128 167.261017,353.159546 
	C166.985580,352.969330 167.000366,352.993713 167.011002,353.002869 
z"/>
						<path fill="#000000" opacity="1.000000" stroke="none"
							d="
M255.227997,292.566895 
	C241.504700,294.094788 228.339630,294.503235 216.020691,287.304504 
	C216.608154,285.376160 218.292328,285.025543 219.801331,285.938416 
	C230.153275,292.200684 241.354568,289.770020 252.354843,289.290192 
	C259.035370,288.998718 265.591675,288.236572 271.124390,283.786896 
	C272.409637,282.753235 273.969055,283.112000 274.857727,285.332092 
	C268.558868,288.047150 262.693604,291.740936 255.227997,292.566895 
z"/>
						<path fill="#000000" opacity="1.000000" stroke="none"
							d="
M256.139343,297.907715 
	C252.999710,303.896423 242.815308,304.702637 238.414749,298.311951 
	C244.944763,300.527557 250.471024,299.608673 256.139343,297.907715 
z"/>
						<path fill="#000000" opacity="1.000000" stroke="none"
							d="
M179.009506,149.979843 
	C170.633408,148.821381 162.223221,149.414703 153.839523,150.016586 
	C153.740219,147.761337 155.112198,147.826797 156.201019,147.598679 
	C160.309326,146.738007 164.488922,146.562576 168.645050,146.815826 
	C185.698563,147.855042 197.348190,139.316559 206.410385,125.916885 
	C210.647354,119.651932 213.083145,112.517387 216.506073,105.863312 
	C226.657364,86.129372 253.733124,74.513596 274.962799,81.064240 
	C281.446014,83.064705 286.746002,86.956856 289.663208,93.556145 
	C287.789307,94.603584 287.207977,93.156380 286.604492,92.410225 
	C277.914734,81.665695 266.228180,80.254883 253.943878,82.860748 
	C236.530182,86.554703 223.243973,95.753670 216.393402,113.117699 
	C212.152008,123.868309 206.298553,133.730377 196.840576,141.021744 
	C191.600311,145.061569 185.652573,147.453659 179.259201,149.799942 
	C178.991272,150.008072 179.009506,149.979843 179.009506,149.979843 
z"/>
						<path fill="#000000" opacity="1.000000" stroke="none"
							d="
M178.980286,150.021469 
	C180.611526,149.805496 182.270218,149.295425 183.893372,149.390686 
	C198.314835,150.236984 210.134872,143.357910 216.731766,130.189224 
	C219.251068,125.160179 221.454056,119.885170 227.876205,118.407936 
	C228.885040,118.175880 229.602234,116.489227 230.367294,115.413307 
	C236.779556,106.395508 244.759949,99.410500 255.178040,95.218819 
	C262.901611,92.111259 270.808716,92.377419 278.787140,93.010803 
	C280.302246,93.131096 282.156372,93.334015 282.961151,95.083885 
	C282.786316,96.066315 282.106323,96.146675 281.465149,95.996513 
	C260.915741,91.183472 246.003265,100.123642 233.848267,115.603973 
	C228.192413,122.807144 224.481934,131.350159 218.498932,138.335495 
	C214.034988,143.547272 209.703201,148.012039 201.970459,149.585083 
	C196.019501,150.795670 190.403168,153.203568 184.130692,152.218094 
	C182.476395,151.958176 180.838379,151.678970 179.252136,150.270737 
	C179.009506,149.979843 178.991272,150.008072 178.980286,150.021469 
z"/>
						<path fill="#000000" opacity="1.000000" stroke="none"
							d="
M377.140259,199.566956 
	C377.485199,200.434097 377.666138,201.025162 377.847076,201.616226 
	C371.803223,195.180115 364.320740,190.661224 357.686890,185.094986 
	C365.546173,187.929367 372.450531,192.005966 377.140259,199.566956 
z"/>
						<path fill="#000000" opacity="1.000000" stroke="none"
							d="
M220.995850,182.013672 
	C229.135956,186.322968 236.518829,185.223221 243.669174,178.701019 
	C249.798569,173.110046 252.991287,165.895050 254.710312,157.933594 
	C255.263275,155.372650 255.739014,152.795013 256.338989,149.777908 
	C257.135376,150.490417 257.743683,150.781448 257.742493,151.069901 
	C257.684570,164.781357 250.838196,181.709412 235.906372,186.328979 
	C228.126495,188.735916 216.723068,185.199493 211.274933,177.279465 
	C215.193146,179.176987 217.929840,180.502319 220.995850,182.013672 
z"/>
						<path fill="#000000" opacity="1.000000" stroke="none"
							d="
M278.055084,148.833328 
	C277.167267,158.653320 275.226746,167.654526 268.994629,175.147659 
	C268.178986,176.128326 267.632202,177.482773 264.565277,177.695938 
	C270.407104,169.236786 274.505615,160.816010 275.571808,151.313858 
	C276.649750,141.706863 272.660767,132.773102 272.109955,123.322128 
	C276.445068,131.095734 277.522614,139.675446 278.055084,148.833328 
z"/>
					</svg>
					<div className="options-game1">
						<div className="color-options">
							<label htmlFor="color-input">Cor: </label>
							<input type="color" id="color-input" value={currentColor} onChange={handleColorChange} ref={colorInputRef} />
						</div>
						<div className="size-game1">
							<label htmlFor="size-input">Tamanho do pincel:</label>
							<input type="range" min="5" max="70" value={currentSize} id="size-input" onChange={handleSizeChange} ref={sizeInputRef} style={{ borderRadius: "50px" }} />
						</div>
					</div>
					<div className="button-game1-draw1">
						<Link to="/jogo1tela2">
							<ButtonDrawGames props_button_draw={"Avançar"} />
						</Link>
					</div>

				</div>

			</div>

		</>
	);
};

export default ScreenOne;
