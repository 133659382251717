import React from 'react'
import Credit from './Credit';
import './Credit.css';

const Debit = () => {
  
  return (
    <div>
       <Credit />
    </div>
  )
}

export default Debit
